var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "event-detail-container",
      attrs: { id: "eventDetailContainer" },
    },
    [
      _c("groupMeetingInfoCard", {
        attrs: {
          infoTemplate: _vm.infoTemplate,
          mianLabelsTemplate: _vm.infoCardmianLabelsTemplate,
          groupInfo: _vm.groupInfo,
          miceInfo: _vm.miceInfo,
        },
        on: { getConfigAndDetail: _vm.getConfigAndDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }