<template>
    <div id="eventDetailContainer" class="event-detail-container">
        <!-- 会议信息 -->
        <groupMeetingInfoCard
            :infoTemplate="infoTemplate"
            @getConfigAndDetail="getConfigAndDetail"
            :mianLabelsTemplate="infoCardmianLabelsTemplate"
            :groupInfo="groupInfo"
            :miceInfo="miceInfo"/>
    </div>
</template>

<script>
/**
 * @description 拼团的会议详情，配置从infocard-template来，专为拼团设置
 * @author Lieutenant Ethan Cho
 */
import groupMeetingInfoCard from "@/views/PfzierEventDetail/groupMeetingInfoCard";

export default {
    name: "index",
    components: {
        groupMeetingInfoCard
    },
    data(){
        return {
            otherBtnList: [],
            miceInfo: {},
            groupInfo: {},
            infoTemplate: {},
            mianLabelsTemplate: {}
        }
    },
    async created() {
        // 获取显示模板
        this.getConfigAndDetail();
    },
    methods: {
        // 获取模板和详情
        async getConfigAndDetail(){
            let params = {
                collection: "cfg-ievent-infoCard-template",
                filter: {
                    platform: "app",
                    tenantCode: this.$cookie.get("tenant") || this.$smartStorage.get("tenant"),
                    tenantId: this.$cookie.get("tenant_id") || "",
                    type: 'groupMeeting'
                },
            };

            let res = await this.$service.GetSettings(params);
            if (res && res.success && res.content) {
                this.infoTemplate = res.content.infoLabels;
                this.mianLabelsTemplate = res.content.mianLabels;
                const configs =  [res.content.detailConfig, res.content.groupConfig];
                const context = this;
                const results = configs.map(async i => {
                    try { // 处理参数
                        eval(i.expr);
                    }catch (e) {
                        console.log(e);
                    }
                    // let  = await this.$service['common'](i.api, i.params);
                    // console.log('结果',res);

                    return await this.$service['common'](i.api, i.params);
                })
                console.log(results);
                this.handleResults(results);

            }
        },
        handleResults(results){
            results.forEach((i, index) => {
                i.then(({content}) => {
                    index === 0 ?  this.miceInfo = content : this.groupInfo = content;
                })
            })
        },
    }
}
</script>

<style scoped>

</style>
