<template>
    <span>
        <template v-if="num||num==0">
            {{ Symbol }} {{ parseFloat(num).toFixed(fixed) }}
        </template>
        <template v-if="!num&&num!=0">
            暂无
        </template>
    </span>
</template>

<script>
export default {
    name: "iFixedNum",
    props: {
        //数据源
        num: {
            type: Number,
            default: 0
        },
        //保留几位小数
        fixed: {
            type: Number,
            default: 2
        },
        //前面带什么符号 如¥ $ (可选项,默认'¥')
        fu: {
            type: String,
            default: '¥'
        },
        // 为空时的提示 如暂无 (可选项,默认'暂无')
        noData: {
            type: String,
            default: "暂无"
        }

    },
    data() {
        return {
            Symbol: '¥',
            noTxt: '暂无'
        }
    },
    created() {
        if (this.fu == null) {
            this.Symbol == '';
        } else {
            this.Symbol = this.fu || '¥';
        }
        if (this.noData == null) {
            this.noTxt == '';
        } else {
            this.noTxt = this.noData || '暂无';
        }
    }
}
</script>

<style scoped>
</style>
