var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.num || _vm.num == 0
        ? [
            _vm._v(
              " " +
                _vm._s(_vm.Symbol) +
                " " +
                _vm._s(parseFloat(_vm.num).toFixed(_vm.fixed)) +
                " "
            ),
          ]
        : _vm._e(),
      !_vm.num && _vm.num != 0 ? [_vm._v(" 暂无 ")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }