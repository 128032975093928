<template>
    <span>{{ txt || '暂无' }}</span>
</template>

<script>
export default {
    name: "iNoCnt",
    props: {
        txt: [String,Number]
    }
}
</script>

<style scoped>

</style>